<template>
  <section class="hero is-secondary is-fullheight noscrollbar">
    <!-- Hero content: will be in the middle -->
    <div class="hero-body px-0 fullwidth">
      <div class="container fullwidth mx-0 has-text-centered">
        <section class="hero is-medium is-primary mb-6">
          <div class="hero-body alignCenter">
            <p class="titleFont has-text-centered is-size-1 has-text-secondary is-all-caps animate__animated animate__fadeInDown">
              <span class="titleFont has-text-centered is-size-5 has-text-secondary mb-0 is-all-caps animate__animated animate__fadeInDown">
                Matt Dean
              </span>
              <br />
              Working Unit Tests
            </p>
          </div>
        </section>

        <div class="buttons is-centered">
          <button
            @click="gotoPodcast()"
            :class="{ 'animate__animated animate__fadeInUp ': showPodcastButton, invisible: !showPodcastButton }"
            class="button is-medium is-primary has-text-secondary"
          >
            <strong class="has-text-secondary">Podcast</strong>
          </button>

          <button
            @click="gotoBlog()"
            :class="{ 'animate__animated animate__fadeInUp ': showBlogButton, invisible: !showBlogButton }"
            class="button is-medium is-primary has-text-secondary"
          >
            <strong class="has-text-secondary">Blog</strong>
          </button>
        </div>
      </div>
    </div>

    <div class="hero-foot">
      <Footer />
    </div>
  </section>
</template>

<script>
  // - components
  // - mixins
  // - props
  // - data
  // - computed
  // - watch
  // - lifecycle
  // - methods
  // - template

  export default {
    data() {
      return {
        showPodcastButton: false,
        showBlogButton: false,
      };
    },

    mounted() {
      this.loadPodcastButton();
      this.loadBlogButton();
    },

    methods: {
      loadPodcastButton() {
        setTimeout(() => {
          this.showPodcastButton = true;
        }, 500);
      },

      loadBlogButton() {
        setTimeout(() => {
          this.showBlogButton = true;
        }, 1000);
      },

      gotoPodcast() {
        window.open('https://anchor.fm/workingunittests', '_blank');
      },

      gotoBlog() {
        window.open('https://blog.workingunittests.com/', '_blank');
      },
    },
  };
</script>

<style scoped>
  .fullwidth {
    width: 100vw;
    max-width: 100vw !important;
  }

  .alignCenter {
    justify-content: center;
  }

  .noscrollbar {
    overflow: hidden !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }

  .invisible {
    opacity: 0;
  }
</style>
